body {
  font-family: Arial, sans-serif;
  background-color: #1C1C3C;
  color: white;
  text-align: center;
}

.p1 {
  font-family: Georgia, serif;
  background-color: #2e2e2e;
  border-radius: 10px;
  padding: 10px;
}
.p2{
  font-family: Georgia, serif;
  padding: 10px;
}

header {
  margin-bottom: 20px;
}

.word-art {
  margin-bottom: 20px;
}

label {
  margin: 0 15px;
}

input {
  padding: 5px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
}

#tables-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust minmax as needed */
  grid-gap: 20px; /* Space between grid items */
  padding: 10px;
}

.table {
  position: relative;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background-color: #006400;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
  font-size: 24px;
}

.seat {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: #8b4513;
  border-radius: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.cards {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.card {
  width: 40px;
  height: 60px;
  background-color: white;
  border: 1px solid black;
  border-radius: 2px;
  margin: 0 2px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.top-container {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   margin-bottom: 10px;
   gap: 20px;
 }
.high-hand-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.high-hand-board {
  flex: 1;
  margin-right: 20px;
  background-color: #38b32f;

}

.stats {
  flex: 0 0 200px;

  background-color: #38b32f;
  padding: 10px;
  border-radius: 5px;
}

.high-hand-cards {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.high-hand-card {
  width: 50px;
  height: 70px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}

.qualifiers-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

input[type="text"], input[type="number"] {
  width: 60px;
  text-align: center;
}
